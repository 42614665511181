import React, {useState, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// import queryString from 'query-string'

import Container from "../components/Container";
import Card from "../components/Сard";
import Dump from "../components/Dump";
import {useProfileRequest} from "../../data/profile";

function Develop() {
    const location = useLocation();
    const history = useHistory();


    const [{data: profile, isLoading, error}] = useProfileRequest([]);

    // const [searchParams, setSearchParams] = useSearchParams();
    // // single-time read
    // const params = Object.fromEntries([...searchParams]);
    // console.log('Mounted:', params);

    useEffect(() => {
        console.log('search > ')
        console.log(location.pathname);
        console.log(location.search);
        console.log('< search')
    }, [location]);

    return (
        <Container title={"Develop"} isLoaded={!isLoading} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            history.push('/dev?a=' + t);
                        }}>Show Results
                        </button>

                        <Dump value={profile}/>
                    </div>
                </div>
            </Card>
        </Container>
    );
}

export default Develop;