import React, {useEffect, useState} from 'react'
import Select from "react-select";

import {consoleLog} from "../../components/console";

export default function SelectMulti({value, updateValue, options, defaultLabel}) {
    const [selectedOption, setSelectedOption] = useState([]);

    const handleTypeSelect = e => {
        // updateValue(e.value);
        // consoleLog('e');
        // consoleLog(e);
        // consoleLog(options);


        let selected = e === null ? [] : e.map(i => i.value);

        // consoleLog(selected);
        setSelectedOption(selected);

        consoleLog(options.filter(o => selected.indexOf(o.value) !== -1));

        updateValue(selected);
    };

    useEffect(() => {
        // consoleLog('value');
        // consoleLog(value);
        setSelectedOption(value);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps


    // useEffect(() => {
    //     console.log('SelectMulti value / value', value)
    //     console.log('SelectMulti value / currentVal', currentVal)
    //
    //     if (!value) {
    //         setCurrentVal("")
    //     } else {
    //         setCurrentVal(value)
    //         // const cv = {value: value, label: defaultLabel};
    //         // options.forEach((e) => {
    //         //     if (e.value === value) {
    //         //         cv.label = e.label;
    //         //     }
    //         // })
    //         // if (cv !== currentVal) {
    //         //     setCurrentVal(cv);
    //         // }
    //     }
    // }, [value]); // eslint-disable-line react-hooks/exhaustive-deps






    // const cv = {value: value, label: defaultLabel};
    // let hasEmpty = false;
    // options.forEach((e) => {
    //     if (e.value === value) {
    //         cv.label = e.label;
    //     }
    //     if (e.value === "") {
    //         hasEmpty = true;
    //     }
    // })
    // if (!hasEmpty && cv.value !== "") {
    //     options.unshift({value : "", label : defaultLabel});
    // }
    // const [currentVal, setCurrentVal] = useState([cv]);
    // const [currentVal, setCurrentVal] = useState('');
    //
    // const updValue = x => {
    //     if (value !== x) {
    //         updateValue(x);
    //     }
    // }
    //
    // useEffect(() => {
    //     console.log('SelectMulti currentVal / value', value)
    //     console.log('SelectMulti currentVal / currentVal', currentVal)
    //
    //     if (!currentVal) {
    //         updValue("");
    //     } else {
    //         let values = currentVal.map(x => x.value);
    //         console.log('SelectMulti values', values)
    //         updValue(values);
    //     }
    // }, [currentVal]); // eslint-disable-line react-hooks/exhaustive-deps
    //
    // useEffect(() => {
    //     console.log('SelectMulti value / value', value)
    //     console.log('SelectMulti value / currentVal', currentVal)
    //
    //     if (!value) {
    //         setCurrentVal("")
    //     } else {
    //         setCurrentVal(value)
    //         // const cv = {value: value, label: defaultLabel};
    //         // options.forEach((e) => {
    //         //     if (e.value === value) {
    //         //         cv.label = e.label;
    //         //     }
    //         // })
    //         // if (cv !== currentVal) {
    //         //     setCurrentVal(cv);
    //         // }
    //     }
    // }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const styles = {
        container: base => ({
            ...base,
            flex: 1
        })
    };

    return (
        <>
            <b>{defaultLabel}</b>
            <Select
                styles={styles}
                options={options}
                isMulti={true}
                clearable={true}
                value={options.filter(o => selectedOption.indexOf(o.value) !== -1)}
                onChange={handleTypeSelect}
            />
        </>
    )
}
