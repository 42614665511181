import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

export default function ExStatZoneListTableParams() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'campaign_origin_id',
                Cell: ({row}) => {
                    let v = row.original.campaign_origin_id;
                    if (row.original.account_type == 1) {
                        v = <a href={"https://ssp.clickadu.com/#/app/campaigns/view/" + v} target="_blank">{v}</a>
                    }
                    // else if (row.original.account_type == 2) {
                    //     v = <a href={"https://partners.adsterra.com/campaign/request/" + v} target="_blank">{v}</a>
                    // }

                    return v
                },
            },
            {
                Header: 'Url',
                accessor: 'campaign_url',
            },
            {
                Header: 'Account',
                accessor: 'account_name',
            },
            {
                Header: 'Zone ID',
                accessor: 'zone_origin_id',
            },
            {
                Header: 'Zone',
                Cell: ({row}) => {
                    return <>
                        {row.original.zone_custom_name && row.original.zone_custom_name.length > 0
                            ? row.original.zone_custom_name + " (" + row.original.zone_name + ")"
                            : row.original.zone_name}
                    </>
                },
            },
            {
                accessor: 'zone_edit',
                Cell: ({row}) => <Link to={"/ex-stat/zone/edit/"+ row.original.zone_id}><Icon.Settings /></Link>,
                width: 100,
            }
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: 'campaign_origin_id',
                desc: true
            }
        ],
        pageSize: 100,
    }

    return [{columns, initialState}];
}
