import React from "react";
import {getAdminsList} from "../../../../components/admins_list";

export default function ListGroupByParams(user) {
    const keyDate = 'date';
    const keyManager = 'manager';
    const keyAccount = 'account';
    const keyPubAccount = 'pub_account';
    const keyCampaign = 'campaign';
    const keyZone = 'zone';
    const keyCountry = 'country';

    const adminsIdList = getAdminsList();

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Manager",
            key: keyManager,
            visible: adminsIdList.includes(user.id),
        },
        {
            title: "Account",
            key: keyAccount,
        },
        {
            title: "Pub Account",
            key: keyPubAccount,
        },
        {
            title: "Campaign",
            key: keyCampaign,
        },
        {
            title: "Zone",
            key: keyZone,
        },
        {
            title: "Country",
            key: keyCountry,
        },
    ], []);

    return [{keyDate, keyManager, keyAccount, keyPubAccount, keyCampaign, keyZone, keyCountry, items}];
}
