import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

export default function DataExStatCampaign() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                let url = window.trackerBaseUrl + "ex-stat/campaign?";

                if ("account" in params) {
                    url += "&account=" + params.account
                }
                if ("zone" in params) {
                    url += "&zone=" + params.zone
                }

                const result = await axios.get(
                    url,
                    {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + ajax.getToken(),
                        },
                    }
                );

                setData(result.data);
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
