import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";
import SegmentRatesList from "./List";
import JSONPretty from 'react-json-pretty';
import SegmentDefaultsList from "./List";

export default function SegmentDefaultsListTableParams(user) {
    const keyDate = 'date';
    const keyCountry = 'country_code';
    const keyZoneId = 'zone';
    const keyZoneOriginId = 'zone_origin_id';
    const keyZoneName = 'zone_name';
    const keyZoneEdit = 'zone_edit';
    const keyRevenueTb = 'revenue_tb';
    const keyProfitTb = 'profit_tb';
    const keyTotalROI = 'total_roi';
    const keyTbOfCost = 'tb_of_cost';

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Country',
                accessor: keyCountry,
            },
            {
                Header: 'Account ID',
                accessor: "account_id",
            },
            {
                Header: 'Account',
                // accessor: keyZoneId,
                accessor: "account_name",
            },
            {
                Header: 'Zone ID',
                accessor: 'zone_id',
            },
            {
                Header: 'Zone',
                accessor: "zone_name",
            },

    //         account_id: "8"
    // country_code: "BS"
    // hash: "43b0431d8e1226abb8da3547dc580485"
    // id: "401"
    // rate: null
    // rate_default: null
    // rate_max: null
    // rate_min: null
    // zone_id: null
            // {
            //     Header: 'Date',
            //     accessor: keyDate,
            // },
            {
                Header: 'Rate min',
                accessor: 'rate_min',
            },
            {
                Header: 'Rate max',
                accessor: 'rate_max',
            },
            {
                Header: 'Rate default',
                accessor: 'rate_default',
            },
            {
                Header: 'Rate',
                accessor: 'rate',
            },

            {
                // id: "actions",
                accessor: keyZoneEdit,
                // accessor: 'id',
/*                Cell: ({ original }) => (
                    <button value={original.id} >
                        {/!* onClick={props.handleClickGroup} *!/}
                        {original.id}
                    </button>
                )*/
                Cell: ({row}) => {
                    // console.log("---- row ----", {row})
                    return <>
                    {/*    <button onClick={(e) => {*/}
                    {/*    e.preventDefault();*/}
                    {/*    console.log("---- button onClick ----", {row})*/}
                    {/*    //setActiveTabID(item.key)*/}
                    {/*}}>Edit</button>*/}

                        <Link to={"/segment-defaults/edit/"+ row.original.id}><Icon.Settings /></Link>
                        </>
                },
                width: 100,
            }
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ],
        pageSize: 100,
    }

    return [{keyZoneId, keyZoneOriginId, keyZoneName, keyDate, keyCountry, keyZoneEdit, keyRevenueTb, keyProfitTb, keyTotalROI, keyTbOfCost, columns, initialState}];
}
