import React from "react";

export default function ExStatDashboardTableParams() {
    const keyDate = 'date';
    const keyManagerID = 'manager';
    const keyManagerName = 'manager_email';
    const keyAccountID = 'account';
    const keyAccountName = 'account_name';
    const keyZoneID = 'origin_zone_id';
    const keyZoneName = 'zone_name';
    const keyCampaignID = 'origin_campaign_id';
    const keyCampaignName = 'campaign_name';
    const keyRevenueTb = 'revenue_tb';
    const keyProfitTb = 'profit_tb';
    const keyTotalROI = 'total_roi';
    const keyTbOfCost = 'tb_of_cost';
    const keyROI = 'roi';
    const keyCountry = 'country';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: keyDate,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Country',
                accessor: keyCountry,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Manager ID',
                accessor: keyManagerID,
            },
            {
                Header: 'Manager',
                accessor: keyManagerName,
            },
            {
                Header: 'Account ID',
                accessor: keyAccountID,
            },
            {
                Header: 'Account',
                accessor: keyAccountName,
            },
            {
                Header: 'Zone ID',
                accessor: keyZoneID,
            },
            {
                Header: 'Zone',
                accessor: keyZoneName,
            },
            {
                Header: 'Campaign ID',
                accessor: keyCampaignID,
            },
            {
                Header: 'Campaign',
                accessor: keyCampaignName,
            },
            // {
            //     Header: 'Impressions',
            //     accessor: 'impressions',
            //     Footer: info => {
            //         const total = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.impressions) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         return <>{Math.round(total * 10000) / 10000}</>
            //     },
            // },
            // {
            //     Header: 'Conversions',
            //     accessor: 'conversions',
            //     Footer: info => {
            //         const total = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         return <>{Math.round(total * 10000) / 10000}</>
            //     },
            // },
            {
                Header: 'Cost, $',
                accessor: 'cost',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{Math.round(total * 10000) / 10000}</>
                },
            },
            // {
            //     Header: 'Revenue, $',
            //     accessor: 'revenue',
            //     Footer: info => {
            //         const total = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         return <>{Math.round(total * 10000) / 10000}</>
            //     },
            // },
            // {
            //     Header: 'Profit, $',
            //     accessor: 'profit',
            //     sortType: 'basic',
            //     // Cell: (d) => {
            //     //     return <>{Math.round((Number(d.row.original.revenue) - Number(d.row.original.cost)) * 10000) / 10000}</>;
            //     // },
            //     Footer: info => {
            //         const total = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.revenue) - Number(row.values.cost) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         return <>{Math.round(total * 10000) / 10000}</>
            //     },
            // },
            // {
            //     Header: 'ROI, %',
            //     accessor: keyROI,
            //     sortType: 'basic',
            //     // Cell: (d) => {
            //     //     if (d.row.original.cost !== null) {
            //     //         return <>{Math.round(((Number(d.row.original.revenue) - Number(d.row.original.cost)) / Number(d.row.original.cost) * 100) * 100) / 100}</>;
            //     //     } else {
            //     //         return <></>
            //     //     }
            //     // },
            //     Footer: info => {
            //         const totalRevenue = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         const totalCost = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         if (totalCost !== 0) {
            //             return <>{Math.round(((totalRevenue - totalCost) / totalCost * 100) * 100) / 100}</>
            //         } else {
            //             return <></>
            //         }
            //     },
            // },
            {
                // Header: 'Revenue TB, $',
                Header: 'Revenue, $',
                accessor: keyRevenueTb,
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    return <>{Math.round(total * 10000) / 10000}</>
                },
            },
            {
                // Header: 'Profit + TB, $',
                Header: 'Profit, $',
                accessor: keyProfitTb,
                sortType: 'basic',
                // Cell: (d) => {
                //     return <>{Math.round((Number(d.row.original.revenue) + Number(d.row.original.revenue_tb) - Number(d.row.original.cost)) * 10000) / 10000}</>;
                // },
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) - Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{Math.round(total * 10000) / 10000}</>
                },
            },
            // {
            //     Header: 'TB / cost, %',
            //     accessor: keyTbOfCost,
            //     sortType: 'basic',
            //     // Cell: (d) => {
            //     //     if (d.row.original.cost !== null) {
            //     //         return <>{Math.round((Number(d.row.original.revenue_tb) / Number(d.row.original.cost) * 100) * 100) / 100}</>;
            //     //     } else {
            //     //         return <></>
            //     //     }
            //     // },
            //     Footer: info => {
            //         const totalRevenueTb = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         const totalCost = React.useMemo(
            //             () =>
            //                 info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
            //             [info.rows]
            //         )
            //
            //         if (totalCost !== 0) {
            //             return <>{Math.round((totalRevenueTb / totalCost * 100) * 100) / 100}</>
            //         } else {
            //             return <></>
            //         }
            //     },
            // },
            {
                // Header: 'Total ROI, %',
                Header: 'ROI, %',
                accessor: keyTotalROI,
                sortType: 'basic',
                // Cell: (d) => {
                //     if (d.row.original.cost !== null) {
                //         return <>{Math.round(((Number(d.row.original.revenue) + Number(d.row.original.revenue_tb) - Number(d.row.original.cost)) / Number(d.row.original.cost) * 100) * 100) / 100}</>;
                //     } else {
                //         return <></>
                //     }
                // },
                Footer: info => {
                    const totalRevenue = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{Math.round(((totalRevenue - totalCost) / totalCost * 100) * 100) / 100}</>
                    } else {
                        return <></>
                    }
                },
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return [{
        keyDate, keyManagerID, keyManagerName, keyAccountID, keyAccountName,
        keyZoneID, keyZoneName, keyCampaignID, keyCampaignName, keyCountry,
        keyRevenueTb, keyProfitTb, keyTotalROI, keyTbOfCost,
        columns, initialState
    }];
}
