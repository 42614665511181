import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "../../../Settings/Tab";
import ButtonSubmit from "../../../../components/ButtonSubmit";
import AlertSuccess from "../../../../components/AlertSuccess";
import {useInput} from "../../../../../components/input-hook";
import {useCampaignCreate} from "./DataCreate";
import SelectMulti from "../../../../components/SelectMulti";
import ajax from "../../../../../data/ajax";
import axios from "axios";
import {consoleLog} from "../../../../../components/console";

export default function ExStatCampaignClickaduCreateFormTabMain({tabActiveID}) {
    const [isLoading, setIsLoading] = useState(false);

    const [listCountries, setListCountries] = useState([]);

    const [{
        data: formData,
        setFormData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useCampaignCreate();

    const {
        value: url,
        setValue: setUrl,
        error: errorUrl,
        setError: setErrorUrl,
        bind: bindUrl
    } = useInput('');
    const {
        value: sourceZones,
        setValue: setSourceZones,
        error: errorSourceZones,
        setError: setErrorSourceZones,
        bind: bindSourceZones
    } = useInput('');
    const {
        value: price,
        setValue: setPrice,
        error: errorPrice,
        setError: setErrorPrice,
        bind: bindPrice
    } = useInput('');
    const {
        value: countries,
        setValue: setCountries,
        error: errorCountries,
        setError: setErrorCountries,
        bind: bindCountries
    } = useInput('');


    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
            };

            const promises = [
                axios.get(window.trackerBaseUrl + "country-list", requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLog(results)

                const countries = results[0].data.map(i => {
                    return {value: i.code, label: i.code + " / " + i.name}
                });
                setListCountries(countries);

                consoleLog(listCountries)

                let zones = ["1890436","1692206","1658615","1780021","1754564","1801441","1791726","1776543","1889347","1898943","1822842","1892598","1881927","1783372","1854847","1898079","1909807","1882176","1352060","1894496","1884922","1845003"];
                setSourceZones(zones.join(', '));

                // setUrlParams({
                //     zone: 'none',
                //     account: 'none',
                // });

                setIsLoading(false);
            });
        };

        fetchData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            url: url,
            source_zones: sourceZones.split(',').map(x => x.trim()),
            price: price,
            countries: countries,
            // slug: slug,
        };
        setFormData(data);
    }

    return (
        <Tab tabID="main" tabActiveID={tabActiveID} isLoading={isLoading} title="">
            <form onSubmit={handleSubmit}>
                {
                    formData && <AlertSuccess message="Data save successfully"/>
                }

                <div className="form-group">
                    <label htmlFor="inputUrl">Url</label>
                    <input type="text" id="inputUrl"
                           placeholder="Url"
                           className={"form-control " + (errorUrl.length > 0 ? "is-invalid" : '')}
                           {...bindUrl}/>
                    <div className="invalid-feedback">{errorUrl}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputSourceZones">Source Zones</label>
                    <input type="text" id="inputSourceZones"
                           placeholder="Source Zones"
                           className={"form-control " + (errorSourceZones.length > 0 ? "is-invalid" : '')}
                           {...bindSourceZones}/>
                    <div className="invalid-feedback">{errorSourceZones}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputPrice">Price</label>
                    <input type="text" id="inputPrice"
                           placeholder="Price"
                           className={"form-control " + (errorPrice.length > 0 ? "is-invalid" : '')}
                           {...bindPrice}/>
                    <div className="invalid-feedback">{errorPrice}</div>
                </div>

                <div className="form-group">
                    <label>Countries</label>
                    <SelectMulti value={countries} updateValue={v => setCountries(v)}
                                    options={listCountries}/>
                    {
                        errorCountries &&
                        <div className="invalid-feedback" style={{display: "block"}}>{errorCountries}</div>
                    }
                </div>

                <ButtonSubmit isLoading={updateIsLoading} text="Save"/>
            </form>
        </Tab>
    )
}

ExStatCampaignClickaduCreateFormTabMain.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
