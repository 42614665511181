import React from 'react';
import styled from "styled-components";

const Menu = styled.ul`
    margin-right: 75px;
`

export default function Footer() {
    const menuItems = React.useMemo(
        () => [
            // {
            //     title: 'Support',
            //     link: '/support',
            // },
            {
                title: 'Help Center',
                link: 'http://help.a2tag.com/en/',
            },
            {
                title: 'Privacy',
                link: process.env.REACT_APP_OWNER_DOMAIN + '/privacy-policy/',
            },
            {
                title: 'Terms',
                link: process.env.REACT_APP_OWNER_DOMAIN + '/terms-of-use/',
            },
        ],
        []
    );

    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-left">
                        <p className="mb-0">
                            <a href={process.env.REACT_APP_OWNER_DOMAIN + '/'} className="text-muted">
                                <strong>© 2020-2021 — A2Tag - CPA Network & Agency. All Rights Reserved.</strong>
                            </a> &copy;
                        </p>
                    </div>
                    <div className="col-6 text-right">
                        {
                            process.env.REACT_APP_TYPE === "lss" &&
                            <Menu className="list-inline">
                                {menuItems.map((item, i) => {
                                    return (
                                        <li className="list-inline-item" key={i}>
                                            <a className="text-muted"
                                               href={item.link}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                            >{item.title}</a>
                                        </li>
                                    )
                                })}
                            </Menu>
                        }
                    </div>
                </div>
                <div className="row text-muted">
                    <div className="col-12 text-left">
                        <p className="mb-0">
                            app version v{process.env.REACT_APP_VERSION}
                            {
                                process.env.NODE_ENV !== 'production'
                                && <> {process.env.NODE_ENV}</>
                            }
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
