import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import Table from "../../../components/TablePaginated";
import DataModel from "../../../../data/DataExStatCampaign";
import TableParams from "./ListTableParams";
import {consoleLog} from "../../../../components/console";
import ajax from "../../../../data/ajax";
import SelectMulti from "../../../components/SelectMulti";
import {getZoneRevenueModelName} from "../../../../components/zone_revenue_model";
import getZoneRevenueModelsList from "../../../../data/zone_revenue_models_list";
import Loading from "../../../components/Loading";

const ExStatCampaignList = ({user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [{
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [filterAccount, setFilterAccount] = useState('');
    const [filterZone, setFilterZone] = useState('');
    const [filterButton, setFilterButton] = useState(0);

    const [listAccount, setListAccount] = useState([]);
    const [listZone, setListZone] = useState([]);

    const [{data, isLoading}, setUrlParams] = DataModel();

    useEffect(() => {
        setUrlParams({});

        const fetchData = async () => {
            const requestConfig = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
            };

            const promises = [
                axios.get(window.trackerBaseUrl + "ex-stat/accounts-list", requestConfig),
                axios.get(window.trackerBaseUrl + "ex-stat/zone-list?", requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLog(results)

                const accounts = results[0].data.map(i => {
                    return {value: i.id, label: i.name}
                });
                setListAccount(accounts);

                const zones = results[1].data.map(i => {
                    let title = i.custom_title.length > 0
                        ? i.custom_title + " (" + i.title + ")"
                        : i.title;

                    return {
                        value: i.id,
                        label: i.origin_zone_id + " / " + title + " / " + getZoneRevenueModelName(i.custom_revenue_model)
                    }
                });
                setListZone(zones);

                // const requestVars = results[2].data.map(i => {
                //     return {value: i.request_var, label: i.request_var}
                // });
                // setListRequestVar(requestVars);

                setIsLoaded(true);
            });
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let params = {
            account: filterAccount,
            zone: filterZone,
        }

        setUrlParams(params)
    }, [filterAccount, filterZone, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Campaigns"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-12">
                        <Link to={"/ex-stat/campaign/create"}><button className="btn btn-primary">Create clickadu campaign</button></Link>
                    </div>
                </div>
            </Card>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <SelectMulti value={filterAccount} updateValue={v => setFilterAccount(v)}
                                     options={listAccount} defaultLabel="Select account"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterZone} updateValue={v => setFilterZone(v)}
                                     options={listZone} defaultLabel="Select zone"/>
                    </div>
                    <div className="col-md-6">
{/*
                        <SelectMulti value={filterRevenueModel} updateValue={v => setFilterRevenueModel(v)}
                                     options={getZoneRevenueModelsList()} defaultLabel="Select revenue type"/>
*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}/>
                }
            </Card>
        </Container>
    );
}

export default ExStatCampaignList;
