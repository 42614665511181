import React from "react";
import {getAdminsList} from "../../../../components/admins_list";

export default function ListGroupByParams(user) {
    const keyDate = 'date';
    const keyManager = 'manager';
    const keyAccount = 'account';
    const keyPubAccount = 'pub_account';
    const keyZone = 'zone';

    const adminsIdList = getAdminsList();

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        // {
        //     title: "Manager",
        //     key: keyManager,
        //     visible: adminsIdList.includes(user.id),
        // },
        // {
        //     title: "Account",
        //     key: keyAccount,
        // },
        // {
        //     title: "Pub Account",
        //     key: keyPubAccount,
        // },
        // {
        //     title: "Zone",
        //     key: keyZone,
        // },
    ], []);

    return [{keyDate, keyManager, keyAccount, keyPubAccount, keyZone, items}];
}
