import React, {useEffect, useState} from "react";
import axios from "axios";

import ajax from "./ajax";
import {consoleLog} from "../components/console";

export const useZoneRequest = (zone_id) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        const url = process.env.REACT_APP_API_URL + "ex-stat/zone/" + zone_id;

        axios.get(url, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + ajax.getToken(),
            },
            responseType: 'json',
        })
            .then((response) => {
                consoleLog(response);
                setData(response.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.replace("/index.html");
                }
                consoleLog(error);
                setError(error);
            })
            .then(() => setIsLoading(false));
    }, []);

    return [{data, isLoading, error}];
};

export const useZoneUpdate = (zone_id) => {
    const [requestData, setRequestData] = useState(null);
    const [data, setData] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (requestData) {
            consoleLog(requestData);

            setIsLoading(true);
            setError(null);
            setDataErrors([]);

            const url = process.env.REACT_APP_API_URL + "ex-stat/zone/" + zone_id;

            axios.put(url, requestData, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
                responseType: 'json',
            })
                .then((response) => {
                    consoleLog("response", response);
                    setData(response.data);
                })
                .catch((error) => {
                    consoleLog("error", error, error.message);
                    if (error.response.status === 401) {
                        window.location.replace("/index.html");
                    }else if (error.response.status === 422) {
                        setDataErrors(error.response.data);
                    } else {
                        setError(error);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                    window.scrollTo(0, 0);
                });
        }
    }, [requestData]);

    return [{data, setRequestData, isLoading, error, dataErrors}];
};
