import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import FilterGroupBy from "../../../components/FilterGroupBy";
import Table from "../../../components/Table";
import DateRangePicker from "../../../components/DateRangePicker";
import DataModel from "../../../../data/DataExStatDashboard3";
import TableParams from "./ListTableParams";
import GroupByParams from "./ListGroupByParams";
import {consoleLog} from "../../../../components/console";
import ajax from "../../../../data/ajax";
import SelectMulti from "../../../components/SelectMulti";
import Loading from "../../../components/Loading";
import {getAdminsList} from "../../../../components/admins_list";
import getZoneRevenueModelsList from "../../../../data/zone_revenue_models_list";

const ExStatDashboardList = ({user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const history = useHistory();

    const adminsIdList = getAdminsList();

    const [{
        keyDate: tableKeyDate,
        keyManagerID: tableKeyManagerID,
        keyManagerName: tableKeyManagerName,
        keyAccountID: tableKeyAccountID,
        keyAccountName: tableKeyAccountName,
        keyZoneID: tableKeyZoneID,
        keyZoneName: tableKeyZoneName,
        keyRevenueTb: tableKeyRevenueTb,
        keyProfitTb: tableKeyProfitTb,
        keyTotalROI: tableKeyTotalROI,
        keyTbOfCost: tableKeyTbOfCost,
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [{
        keyDate: filterGroupByKeyDate,
        keyManager: filterGroupByKeyManager,
        keyAccount: filterGroupByKeyAccount,
        keyPubAccount: filterGroupByKeyPubAccount,
        keyZone: filterGroupByKeyZone,
        items: filterGroupByItems,
    }] = GroupByParams(user);

    const [filterGroupBy, setFilterGroupBy] = useState(filterGroupByKeyDate);
    const [tableHiddenColumns, setTableHiddenColumns] = useState([tableKeyDate]);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [filterAccounts, setFilterAccounts] = useState([])
    const [filterManagers, setFilterManagers] = useState([])
    const [filterButton, setFilterButton] = useState(0);

    const [listManagers, setListManagers] = useState([]);
    const [listAccount, setListAccount] = useState([]);

    const [params, setParams] = useState({});
    const [{data, isLoading}, setUrlParams] = DataModel();

    // const defaultHiddenColumns = user.id == 79 ? [keyZoneId, tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost] : [keyZoneId];
    const defaultHiddenColumns = adminsIdList.includes(user.id)
        ? []
        : [tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost];
    // const defaultHiddenColumns = isAdmin ? [] : [keyUserEmail, keyProfit]

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
            };

            const promises = [
                axios.get(window.trackerBaseUrl + "ex-stat/accounts-list", requestConfig),
            ];
            if (adminsIdList.includes(user.id)) {
                promises.push(axios.get(window.trackerBaseUrl + "ex-stat/account-managers-list", requestConfig))
            }
            Promise.all(promises).then(function (results) {
                // consoleLog(results)

                const accounts = results[0].data.map(i => {
                    return {value: i.id, label: i.id + " / " + i.name}
                });
                setListAccount(accounts);

                let managers = [];
                if (adminsIdList.includes(user.id)) {
                    managers = results[1].data.map(i => {
                        return {value: i.id, label: i.id + " / " + i.email}
                    });
                    setListManagers(managers);
                }

                const searchParams = new URLSearchParams(location.search)
                const p = Object.fromEntries(searchParams);

                if (("group_by" in p) && filterGroupByItems.map(e => e.key).indexOf(p.group_by) !== -1) {
                    setFilterGroupBy(p.group_by);
                } else {
                    p.group_by = filterGroupBy;
                }

                if (!("date_from" in p)) {
                    p.date_from = calendarDates.start
                }
                if (!("date_to" in p)) {
                    p.date_to = calendarDates.end
                }
                setCalendarDates({
                    start: p.date_from,
                    end: p.date_to,
                });

                const accountsValues = accounts.map(e => e.value);
                let urlAccounts = ("account" in p) ? p.account.split(',') : filterAccounts;
                urlAccounts = urlAccounts.filter(v => accountsValues.indexOf(v) !== -1)
                setFilterAccounts(urlAccounts);
                p.account = urlAccounts;

                const managersValues = managers.map(e => e.value);
                let urlManagers = ("manager" in p) ? p.manager.split(',') : filterManagers;
                urlManagers = urlManagers.filter(v => managersValues.indexOf(v) !== -1)
                setFilterManagers(urlManagers);
                p.manager = urlManagers;

                setParams(p);

                setIsLoaded(true);
            });
        };

        setIsLoaded(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterGroupBy === filterGroupByKeyManager) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyAccountID, tableKeyAccountName, tableKeyZoneID, tableKeyZoneName]))
        } else if (filterGroupBy === filterGroupByKeyAccount || filterGroupBy === filterGroupByKeyPubAccount) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName]))
        } else if (filterGroupBy === filterGroupByKeyDate) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName]))
        } else if (filterGroupBy === filterGroupByKeyZone) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName]))
        } else {
            setTableHiddenColumns(defaultHiddenColumns)
        }
    }, [filterGroupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            let params = {
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                manager: filterManagers,
                account: filterAccounts,
                group_by: filterGroupBy,
            }

            setParams(params)
        }
    }, [filterGroupBy, calendarDates, filterAccounts, filterManagers, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        history.push(location.pathname + '?' + (new URLSearchParams(params)).toString());
        setUrlParams(params);
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Dashboard Clickadu"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <b>Date</b>
                        <DateRangePicker dates={calendarDates} updateDates={v => setCalendarDates(v)}/>
                    </div>
{/*                    <div className="col-md-3">
                        <SelectMulti value={filterAccounts} updateValue={v => setFilterAccounts(v)}
                                     options={listAccount} defaultLabel="Select account"/>
                    </div>
                    <div className="col-md-3">
                        {
                            adminsIdList.includes(user.id) &&
                            <SelectMulti value={filterManagers} updateValue={v => setFilterManagers(v)}
                                         options={listManagers} defaultLabel="Select manager"/>
                        }
                    </div>*/}
                    <div className="col-md-3">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FilterGroupBy items={filterGroupByItems} value={filterGroupBy}
                                       updateValue={v => setFilterGroupBy(v)}/>
                    </div>
                    <div className="col-md-6 text-center">&nbsp;</div>
                </div>
            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}
                              hiddenColumns={tableHiddenColumns}/>
                }
            </Card>
        </Container>
    );
}

export default ExStatDashboardList;
