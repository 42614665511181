import React from "react";

import DataStates from "./data_states";

export default function NftCollectionListTableParams() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Contract',
                accessor: 'contract',
                Cell: ({row}) => {
                    return <a href={"https://etherscan.io/address/" + row.original.contract} target="_blank">{row.original.contract}</a>
                },
            },
            {
                Header: 'Slug',
                accessor: 'slug',
                Cell: ({row}) => {
                    return <a href={"https://opensea.io/collection/" + row.original.slug} target="_blank">{row.original.slug}</a>;
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Tokens',
                accessor: 'assets_count',
            },
            {
                Header: 'Floor Price',
                accessor: 'floor_price',
            },
            {
                Header: 'Status',
                Cell: ({row}) => {
                    return <>
                        {DataStates.getDataStateLabel(row.original.data_state)}
{/*
                        {row.original.data_state * 1 === DataStates.DATA_STATUS_NEED_LOAD
                            ? ' (loaded ' + (row.original.assets_count - row.original.status_assets_count) + " of " + row.original.assets_count + ')'
                            : ''}
                            */}
                    </>
                },
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: 'id',
                desc: true
            }
        ],
        pageSize: 100,
    }

    return [{columns, initialState}];
}
