import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

export default function DataExStatDashboard() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // console.log("DataExStatDashboard")

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {

                if (Object.keys(params).length > 0) {
                    let url = window.trackerBaseUrl + "ex-stat/dashboard?" + (new URLSearchParams(params)).toString();

                    const result = await axios.get(url, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + ajax.getToken(),
                        },
                    });

                    let data = result.data.map(e => {
                        const roundBy = (val, by) => Math.round(val * by) / by

                        const cost = Number(e.cost);
                        const revenue_tb = Number(e.revenue_tb);
                        const profit = Number(e.revenue) - cost;
                        const profit_tb = revenue_tb + profit;

                        e.profit = roundBy(profit, 10000);
                        e.profit_tb = roundBy(profit_tb, 10000);

                        e.tb_of_cost = cost === 0 ? null : roundBy(revenue_tb / cost * 100, 100);
                        e.roi = cost === 0 ? null : roundBy(profit / cost * 100, 100);
                        e.total_roi = cost === 0 ? null : roundBy(profit_tb / cost * 100, 100);

                        if ('pub_account' in e && !('account' in e)) {
                            e.account = e.pub_account;
                        }

                        return e;
                    })

                    // setIsLoaded(true);
                    setData(data);
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
