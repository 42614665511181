import React, {useState, useEffect} from 'react';
import {Link, NavLink, useParams} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/TablePaginated";
import DateRangePicker from "../../components/DateRangePicker";
import DataModel from "../../../data/DataDefaultsRates";
import TableParams from "./ListTableParams";
import GroupByParams from "../ExStat/Zone/ListGroupByParams";
import {consoleLog} from "../../../components/console";
import ajax from "../../../data/ajax";
import SelectMulti from "../../components/SelectMulti";
import {getZoneRevenueModelName} from "../../../components/zone_revenue_model";
import Loading from "../../components/Loading";

const SegmentDefaultsList = ({user}) => {
    // let {account_id} = useParams();
    const accountId = 1;

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    // const [data, setData] = useState([]);

    const isAdmin = user.role === 'admin';

    // console.log("ExStatZoneList user", user)

    const [{
        keyZoneId: keyZoneId,
        keyZoneOriginId: keyZoneOriginId,
        keyZoneName: keyZoneName,
        keyDate: tableKeyDate,
        keyCountry: tableKeyCountry,
        keyZoneEdit: tableKeyZoneEdit,
        keyRevenueTb: tableKeyRevenueTb,
        keyProfitTb: tableKeyProfitTb,
        keyTotalROI: tableKeyTotalROI,
        keyTbOfCost: tableKeyTbOfCost,
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [{
        keyDate: filterGroupByKeyDate,
        keyCountry: filterGroupByKeyCountry,
        keyZone: filterGroupByKeyZone,
        items: filterGroupByItems,
    }] = GroupByParams(isAdmin);

    const [filterGroupBy, setFilterGroupBy] = useState(filterGroupByKeyDate);
    const [tableHiddenColumns, setTableHiddenColumns] = useState([tableKeyDate]);

    const [calendarDates, setCalendarDates] = useState({
        // start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [filterCountry, setFilterCountry] = useState('');
    const [filterZone, setFilterZone] = useState('');
    const [filterAccount, setFilterAccount] = useState('');
    const [filterButton, setFilterButton] = useState(0);

    const [listCountry, setListCountry] = useState([]);
    const [listZone, setListZone] = useState([]);
    const [listAccount, setListAccount] = useState([]);

    const [{data, isLoading}, setUrlParams] = DataModel();

    // const defaultHiddenColumns = user.id == 79 ? [keyZoneId, tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost] : [keyZoneId];
    // const defaultHiddenColumns = isAdmin ? [] : [keyUserEmail, keyProfit]
    const defaultHiddenColumns = ['id', 'zone_id', 'account_id', 'rate'];

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
            };

            const promises = [
                axios.get(window.trackerBaseUrl + "country-list", requestConfig),
                // axios.get(window.trackerBaseUrl + "ex-stat/zone-list?account_id=" + accountId, requestConfig),
                axios.get(window.trackerBaseUrl + "ex-stat/zone-list", requestConfig),
                axios.get(window.trackerBaseUrl + "ex-stat/accounts-list", requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLog(results)

                const countries = results[0].data.map(i => {
                    return {value: i.code, label: i.code + " / " + i.name}
                });
                setListCountry(countries);

                const zones = results[1].data.map(i => {
                    let title = i.custom_title.length > 0
                        ? i.custom_title + " (" + i.title + ")"
                        : i.title;

                    return {
                        value: i.id,
                        label: i.origin_zone_id + " / " + title + " / " + getZoneRevenueModelName(i.custom_revenue_model)
                    }
                });
                zones.unshift({value: 'none', label: 'Without zone' });
                setListZone(zones);

                const accounts = results[2].data.map(i => {
                    return {value: i.id, label: i.id + " / " + i.name}
                });
                accounts.unshift({value: 'none', label: 'Without account' });
                setListAccount(accounts);

                // setFilterAccount('none');
                // setFilterZone('none');
                // filterZone = 'none';
                setUrlParams({
                    zone: 'none',
                    account: 'none',
                });

                setIsLoaded(true);
            });
        };

        fetchData();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // if (filterGroupBy === filterGroupByKeyCountry) {
        //     setTableHiddenColumns(defaultHiddenColumns.concat([keyZoneOriginId, keyZoneName, tableKeyDate, tableKeyZoneEdit]))
        // } else if (filterGroupBy === filterGroupByKeyDate) {
        //     setTableHiddenColumns(defaultHiddenColumns.concat([keyZoneOriginId, keyZoneName, tableKeyCountry, tableKeyZoneEdit]))
        // } else if (filterGroupBy === filterGroupByKeyZone) {
        //     setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyCountry]))
        // } else {
        //     setTableHiddenColumns(defaultHiddenColumns)
        // }
        setTableHiddenColumns(defaultHiddenColumns)

        let params = {
            // group: filterGroupBy,
            // date_from: calendarDates.start,
            // date_to: calendarDates.end,
            country: filterCountry,
            zone: filterZone,
            account: filterAccount,
        }

        if (isLoaded) {
            setUrlParams(params)
        }
    }, [filterGroupBy, calendarDates, filterCountry, filterZone, filterAccount, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Rates"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <SelectMulti value={filterCountry} updateValue={v => setFilterCountry(v)}
                                     options={listCountry} defaultLabel="Select country"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterAccount} updateValue={v => setFilterAccount(v)}
                                     options={listAccount} defaultLabel="Select account"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterZone} updateValue={v => setFilterZone(v)}
                                     options={listZone} defaultLabel="Select zone"/>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {/*<FilterGroupBy items={filterGroupByItems} value={filterGroupBy}*/}
                        {/*               updateValue={v => setFilterGroupBy(v)}/>*/}
                    </div>
                    <div className="col-md-6 text-center">
                        { /*
                            ['13'].includes(accountId)
                            && <Link
                                to={"/ex-stat/mapping/add/" + accountId}
                                className="btn btn-success fa-pull-right"
                            >Add Campaign/Zone</Link>
                         */}
                    </div>
                </div>
            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}
                              hiddenColumns={tableHiddenColumns}/>
                }
            </Card>
        </Container>
    );
}

export default SegmentDefaultsList;
