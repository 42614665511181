const USER_ANDREY = 1;
const USER_DMITRIY = 2;

export function getAdminsList() {
    return [USER_ANDREY, USER_DMITRIY];
}

export function getNftAdminsList() {
    return [USER_ANDREY, USER_DMITRIY];
}
