import React, {useState, useEffect} from 'react';
import {useParams, useLocation, useHistory, Link} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import FilterGroupBy from "../../../components/FilterGroupBy";
import Table from "../../../components/Table";
import DateRangePicker from "../../../components/DateRangePicker";
import DataModel from "../../../../data/DataExStatZone";
import TableParams from "./ListTableParams";
import GroupByParams from "./ListGroupByParams";
import ajax from "../../../../data/ajax";
import SelectMulti from "../../../components/SelectMulti";
import {getZoneRevenueModelName} from "../../../../components/zone_revenue_model";
import getZoneRevenueModelsList from "../../../../data/zone_revenue_models_list";
import Loading from "../../../components/Loading";
import {getAdminsList} from "../../../../components/admins_list";
import * as Icon from "react-feather";

const ExStatZoneList = ({user, balances}) => {
    let {account_id} = useParams();
    const accountId = account_id;

    const location = useLocation();
    const history = useHistory();

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    // console.log("ExStatZoneList user", user)

    const [{
        keyZoneId: keyZoneId,
        keyZoneOriginId: keyZoneOriginId,
        keyZoneName: keyZoneName,
        keyDateTail: tableKeyDateTail,
        keyDate: tableKeyDate,
        keyCountry: tableKeyCountry,
        keyZoneEdit: tableKeyZoneEdit,
        keyRevenueTb: tableKeyRevenueTb,
        keyProfitTb: tableKeyProfitTb,
        keyTotalROI: tableKeyTotalROI,
        keyTbOfCost: tableKeyTbOfCost,
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [{
        keyDate: filterGroupByKeyDate,
        keyDateTail: filterGroupByKeyDateTail,
        keyCountry: filterGroupByKeyCountry,
        keyZone: filterGroupByKeyZone,
        // keyCampaign: filterGroupByKeyCampaign,
        items: filterGroupByItems,
    }] = GroupByParams();

    const [filterGroupBy, setFilterGroupBy] = useState(filterGroupByKeyDate);
    const [tableHiddenColumns, setTableHiddenColumns] = useState([tableKeyDate]);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [filterCountry, setFilterCountry] = useState([]);
    const [filterZone, setFilterZone] = useState([]);
    const [filterRevenueModel, setFilterRevenueModel] = useState([]);
    const [filterButton, setFilterButton] = useState(0);

    const [listCountry, setListCountry] = useState([]);
    const [listZone, setListZone] = useState([]);

    const [params, setParams] = useState({});
    const [{data, isLoading}, setUrlParams] = DataModel(accountId);

    const adminsIdList = getAdminsList();

    const defaultHiddenColumns = adminsIdList.includes(user.id) ? [keyZoneId] : [keyZoneId, tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost];

    // console.log("defaultHiddenColumns", defaultHiddenColumns)
    // console.log("adminsIdList.includes(user.id)", adminsIdList.includes(user.id))

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
            };

            const promises = [
                axios.get(window.trackerBaseUrl + "country-list", requestConfig),
                axios.get(window.trackerBaseUrl + "ex-stat/zone-list?account_id=" + accountId, requestConfig),
                // axios.get(window.trackerBaseUrl + "ex-stat/request-vars-list", requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                // consoleLog(results);

                const countries = results[0].data.map(i => {
                    return {value: i.code, label: i.code + " / " + i.name}
                });
                setListCountry(countries);

                const zones = results[1].data.map(i => {
                    let title = i.custom_title.length > 0
                        ? i.custom_title + " (" + i.title + ")"
                        : i.title;

                    return {
                        value: i.id,
                        label: i.origin_zone_id + " / " + title + " / " + getZoneRevenueModelName(i.custom_revenue_model)
                    }
                });
                setListZone(zones);

                // const requestVars = results[2].data.map(i => {
                //     return {value: i.request_var, label: i.request_var}
                // });
                // setListRequestVar(requestVars);

                const searchParams = new URLSearchParams(location.search)
                const p = Object.fromEntries(searchParams);

                if (("group_by" in p) && filterGroupByItems.map(e => e.key).indexOf(p.group_by) !== -1) {
                    setFilterGroupBy(p.group_by);
                } else {
                    p.group_by = filterGroupBy;
                }

                if (!("date_from" in p)) {
                    p.date_from = calendarDates.start
                }
                if (!("date_to" in p)) {
                    p.date_to = calendarDates.end
                }
                setCalendarDates({
                    start: p.date_from,
                    end: p.date_to,
                });

                const countriesValues = countries.map(e => e.value);
                let urlCountries = ("country" in p) ? p.country.split(',') : filterCountry;
                urlCountries = urlCountries.filter(v => countriesValues.indexOf(v) !== -1)
                setFilterCountry(urlCountries);
                p.country = urlCountries;

                const zonesValues = zones.map(e => e.value);
                let urlZones = ("zone" in p) ? p.zone.split(',') : filterZone;
                urlZones = urlZones.filter(v => zonesValues.indexOf(v) !== -1)
                setFilterZone(urlZones);
                p.zone = urlZones;

                const revenueModelValues = getZoneRevenueModelsList().map(e => e.value);
                let urlRevenueModels = ("revenue_model" in p) ? p.revenue_model.split(',') : filterRevenueModel;
                urlRevenueModels = urlRevenueModels.filter(v => revenueModelValues.indexOf(v) !== -1)
                setFilterRevenueModel(urlRevenueModels);
                p.revenue_model = urlRevenueModels;

                setParams(p);

                setIsLoaded(true);
            });
        };

        setIsLoaded(false);
        fetchData();
    }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterGroupBy === filterGroupByKeyCountry) {
            setTableHiddenColumns(defaultHiddenColumns.concat([keyZoneOriginId, keyZoneName, tableKeyDate, tableKeyDateTail, tableKeyZoneEdit]))
        } else if (filterGroupBy === filterGroupByKeyDate) {
            setTableHiddenColumns(defaultHiddenColumns.concat([keyZoneOriginId, keyZoneName, tableKeyCountry, tableKeyZoneEdit, tableKeyDateTail]))
        } else if (filterGroupBy === filterGroupByKeyDateTail) {
            setTableHiddenColumns(defaultHiddenColumns.concat([keyZoneOriginId, keyZoneName, tableKeyCountry, tableKeyZoneEdit]))
        } else if (filterGroupBy === filterGroupByKeyZone) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyDateTail, tableKeyCountry]))
        // } else if (filterGroupBy === filterGroupByKeyZone) {
            // setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyCountry]))
        } else {
            setTableHiddenColumns(defaultHiddenColumns)
        }
    }, [filterGroupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            let params = {
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                country: filterCountry,
                zone: filterZone,
                revenue_model: filterRevenueModel,
                group_by: filterGroupBy,
            }

            console.log('params isLoaded')
            console.log(params)

            setParams(params);
        }
    }, [filterGroupBy, calendarDates, filterCountry, filterZone, filterRevenueModel, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log('params')
        console.log(params)

        history.push(location.pathname + '?' + (new URLSearchParams(params)).toString());
        setUrlParams(params);
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Statistics"}
                   titleInfo={account_id in balances ? "account balance: " + balances[account_id] + " USD" : ""}
                   isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <b>{filterGroupBy === filterGroupByKeyDateTail ? <>Date spend</> : <>Date</>}</b>
                        <DateRangePicker dates={calendarDates} updateDates={v => setCalendarDates(v)}/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterCountry} updateValue={v => setFilterCountry(v)}
                                     options={listCountry} defaultLabel="Select country"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterZone} updateValue={v => setFilterZone(v)}
                                     options={listZone} defaultLabel="Select zone"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterRevenueModel} updateValue={v => setFilterRevenueModel(v)}
                                     options={getZoneRevenueModelsList()} defaultLabel="Select revenue type"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FilterGroupBy items={filterGroupByItems} value={filterGroupBy}
                                       updateValue={v => setFilterGroupBy(v)}/>
                    </div>
                    <div className="col-md-6 text-center">
                        { /*
                            ['13'].includes(accountId)
                            && <Link
                                to={"/ex-stat/mapping/add/" + accountId}
                                className="btn btn-success fa-pull-right"
                            >Add Campaign/Zone</Link>
                         */}
                    </div>
                </div>
            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}
                              hiddenColumns={tableHiddenColumns}/>
                }
            </Card>
        </Container>
    );
}

export default ExStatZoneList;
