import React, {useState} from 'react';
import {useParams} from "react-router-dom";

import Container from "../../../components/Container";
import TabMain from "./AddFormTabMain";

export default function NftCollectionAddForm() {
    const [activeTabID, setActiveTabID] = useState('main');

    const menuItems = [
        {key: 'main', title: 'Main'},
    ];

    return (
        <Container title={"Add Collection"}>
            <div className="row">
                <div className="col-md-3 col-xl-2">
                    <div className="card">
                        <div className="list-group list-group-flush" role="tablist">
                            {menuItems.map((item) => {
                                return (
                                    <a className={"list-group-item list-group-item-action" + (item.key === activeTabID ? " active" : "")}
                                       href={"#" + item.key}
                                       key={item.key}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           setActiveTabID(item.key)
                                       }}
                                    >
                                        {item.title}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-md-9 col-xl-10">
                    <div className="tab-content">
                        <TabMain tabActiveID={activeTabID} />
                    </div>
                </div>
            </div>
        </Container>
    )
}
