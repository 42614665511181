import React, {useEffect, useState} from 'react'
import Select from "react-select";

export default function SelectCountry3({value, updateValue, options, defaultLabel}) {
    const cv = {value: value, label: defaultLabel};
    options.forEach((e) => {
        if (e.value == value) {
            cv.label = e.label;
        }
    })

    const [currentVal, setCurrentVal] = useState(cv);

    useEffect(() => {
        updateValue(currentVal.value);
    }, [currentVal]); // eslint-disable-line react-hooks/exhaustive-deps

    const styles = {
        container: base => ({
            ...base,
            flex: 1
        })
    };

    return (
        <Select
            styles={styles}
            // className="form-control mb-3"
            name={"filter_country"}
            options={options}
            // isMulti={true}
            clearable={true}
            value={currentVal}
            onChange={e => setCurrentVal(e)}
        />
    )
}
