import React, {useEffect, useState} from "react";
import axios from "axios";

import {consoleLog} from "../../../../components/console";
import ajax from "../../../../data/ajax";

export const useCollectionAdd = () => {
    const [requestData, setRequestData] = useState(null);
    const [data, setData] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (requestData) {
            consoleLog(requestData);

            setIsLoading(true);
            setError(null);
            setDataErrors([]);

            const url = process.env.REACT_APP_API_URL + "nft/collection";

            axios.post(url, requestData, {
                headers: ajax.getHeaders(),
                responseType: 'json',
            })
                .then((response) => {
                    consoleLog("response", response);
                    setData(response.data);
                })
                .catch((error) => {
                    consoleLog("error", error, error.message);
                    if (error.response.status === 401) {
                        window.location.replace("/index.html");
                    }else if (error.response.status === 422) {
                        setDataErrors(error.response.data);
                    } else {
                        setError(error);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                    window.scrollTo(0, 0);
                });
        }
    }, [requestData]);

    return [{data, setFormData: setRequestData, isLoading, error, dataErrors}];
};
