import React, {Component, useEffect, useState} from 'react';
import {Route, Switch, Redirect, withRouter, NavLink} from "react-router-dom"
import {useHistory} from "react-router";

import axios from "axios";

import Footer from "./views/Footer";
import Header from "./views/Header";
import Sidebar from "./views/Sidebar";
import Dashboard from "./views/pages/Dashboard";
import Offer from "./views/pages/Offer";
import Offers from "./views/pages/Offers";
import TrafficSources from "./views/pages/TrafficSources";
import Stats from "./views/pages/Stats";
import Settings from "./views/pages/Settings";
import Lss from "./views/pages/Lss";
import Terms from "./views/pages/static/Terms";
import Privacy from "./views/pages/static/Privacy";
import Help from "./views/pages/static/Help";
import Support from "./views/pages/static/Support";
import ajax from "./data/ajax";
import Loading from "./views/components/Loading";
import Intercom from "./views/components/Intercom";
import ScrollToTop from "./views/components/ScrollToTop";
import Login from "./views/pages/Login";
import Payments from "./views/pages/Payments";
import Logout from "./views/pages/Logout";
import Develop from "./views/pages/Develop";
import Rates from "./views/pages/Rates";
import RegisterRecaptcha from "./views/pages/RegisterRecaptcha";
import GTag from "./views/components/GTag";
import Empty from "./views/pages/Empty";
import ExStatZoneList from "./views/pages/ExStat/Zone/List";
import ExStatZoneEditForm from "./views/pages/ExStat/Zone/EditForm";
import {consoleLog} from "./components/console";
import SidebarItemsLss from "./views/SidebarItemsLss";
import * as Icon from "react-feather";
import ExStatCampaignList from "./views/pages/ExStat/Campaign/List";
import ExStatMappingForm from "./views/pages/ExStat/Mapping/Form";
import SegmentRatesList from "./views/pages/SegmentRates/List";
import SegmentDefaultsList from "./views/pages/SegmentDefaults/List";
import SegmentDefaultsEditForm from "./views/pages/SegmentDefaults/EditForm";
import ExStatDashboardList from "./views/pages/ExStat/Dashboard/List";
import ExStatDashboard2List from "./views/pages/ExStat/Dashboard2/List";
import ExStatDashboard3List from "./views/pages/ExStat/Dashboard3/List";
import NftCollectionList from "./views/pages/Nft/Collection/List";
import NftTokenList from "./views/pages/Nft/Token/List";
import {getAdminsList, getNftAdminsList} from "./components/admins_list";
import NftCollectionAddForm from "./views/pages/Nft/Collection/AddForm";
import ExStatCampaignClickaduCreateForm from "./views/pages/ExStat/Campaign/Clickadu/CreateForm";

const App = props => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState([]);

    const history = useHistory();

    const [menuItems, setMenuItems] = useState([]);
    const [userName, setUserName] = useState("");
    const [balances, setBalances] = useState({});

    const adminsIdList = getAdminsList();
    const nftAdminsIdList = getNftAdminsList();

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + ajax.getToken(),
                },
            };

            axios.get(window.trackerBaseUrl + "auth", requestConfig).then(response => {
                // console.log("response", response)
                if (response.data === null) {
                    setIsLoaded(true);
                    setIsAuth(false);
                } else {
                    setIsAuth(true);
                    setUser(response.data);

                    let user = response.data;

                    let uName = (response.data.first_name + " " + response.data.last_name).trim();
                    uName = uName.length > 0 ? uName : response.data.email
                    setUserName(uName);

                    const promises = [];
                    if (process.env.REACT_APP_TYPE === "cabinet") {
                        promises.push(axios.get(window.trackerBaseUrl + "ex-stat/accounts-list", requestConfig))
                    }
                    Promise.all(promises).then(function (results) {
                        let mItems = [];
                        if (process.env.REACT_APP_TYPE === "lss") {
                            mItems = SidebarItemsLss();
                        } else if (process.env.REACT_APP_TYPE === "cabinet") {
                            // console.log("user -----------------", user);
                            // if (adminsIdList.includes(user.id)) {
                                mItems.push({
                                    title: 'Dashboard Push',
                                    link: 'ex-stat/dashboard',
                                    icon: <Icon.TrendingUp/>,
                                })
                                mItems.push({
                                    title: 'Dashboard TB',
                                    link: 'ex-stat/dashboard_tb',
                                    icon: <Icon.TrendingUp/>,
                                })
                                mItems.push({
                                    title: 'Dashboard Clickadu',
                                    link: 'ex-stat/dashboard_clickadu',
                                    icon: <Icon.TrendingUp/>,
                                })
                            // }

                            results[0].data.forEach((item) => {
                                mItems.push({
                                    // title: 'Source: ' + item.name,
                                    title: item.name,
                                    link: 'ex-stat/account/' + item.id,
                                    icon: <Icon.Sliders/>,
                                })

                                balances[item.id] = item.balance;
                            });
                            mItems.push({
                                title: 'Campaigns',
                                link: 'ex-stat/campaign',
                                icon: <Icon.Layers/>,
                            })

                            // console.log("user -----------------", user);
                            if (user.role === 'admin') {
                                mItems.push({
                                    title: 'Rates',
                                    link: 'segment-rate',
                                    icon: <Icon.Layers/>,
                                })
                                mItems.push({
                                    title: 'Rates settings',
                                    link: 'segment-defaults',
                                    icon: <Icon.Layers/>,
                                })
                            }

                            if (nftAdminsIdList.includes(user.id)) {
                                mItems.push({
                                    title: 'NFT Collections',
                                    link: 'nft/collections',
                                    icon: <Icon.Archive/>,
                                })
                                mItems.push({
                                    title: 'NFT Tokens',
                                    link: 'nft/tokens',
                                    icon: <Icon.Image/>,
                                })
                            }
                        }
                        if (process.env.NODE_ENV === "development") {
                            mItems.push({
                                title: 'Development',
                                link: 'dev',
                                icon: <Icon.Command/>,
                            })
                        }
                        setMenuItems(mItems);

                        setIsLoaded(true);
                    });
                }
            })
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        return <div>Ошибка: {error.message}</div>
    } else if (!isLoaded) {
        return <Loading/>
    } else if (!isAuth) {
        return (
            <React.Fragment>
                <GTag/>
                <Switch>
                    <Route history={history} path='/login' component={Login}/>
                    <Route history={history} path='/signup' component={RegisterRecaptcha}/>
                    <Redirect from='/' to='/login'/>
                </Switch>
            </React.Fragment>
        )
    } else {
        return (
            <div className="wrapper">
                <GTag/>
                <Sidebar menuItems={menuItems}/>

                <div className="main">
                    <Header userName={userName}/>

                    <main className="content">
                        <ScrollToTop/>
                        {
                            process.env.REACT_APP_TYPE === "lss" &&
                            process.env.REACT_APP_INTERCOM_ID &&
                            <Intercom
                                appId={process.env.REACT_APP_INTERCOM_ID}
                                email={user.email}
                                userId={user.id}
                                createdAt={user.created_at}
                            />
                        }
                        {
                            process.env.REACT_APP_TYPE === "lss" &&
                            <Switch>
                                <Route history={history} path='/stats'
                                       render={(props) => (
                                           <Lss {...props} user={user}/>
                                       )}
                                />
                                <Route history={history} path='/rates' component={Rates}/>
                                <Route history={history} path='/settings' component={Settings}/>
                                <Route history={history} path='/dev' component={Develop}/>
                                <Route history={history} path='/logout' component={Logout}/>
                                <Redirect from='/' to='/stats'/>
                            </Switch>
                        }
                        {
                            process.env.REACT_APP_TYPE === "cabinet" &&
                            <Switch>
                                <Route history={history} path="/ex-stat/campaign/create"
                                       render={(props) => (
                                           <ExStatCampaignClickaduCreateForm {...props} user={user}/>
                                       )}
                                />
                                <Route history={history} path="/ex-stat/campaign"
                                       render={(props) => (
                                           <ExStatCampaignList {...props} user={user}/>
                                       )}
                                />
                                <Route history={history} path="/ex-stat/account/:account_id"
                                       render={(props) => (
                                           <ExStatZoneList {...props} user={user} balances={balances}/>
                                       )}
                                />
                                <Route history={history} path="/ex-stat/zone/edit/:zone_id"
                                       render={(props) => (
                                           <ExStatZoneEditForm {...props} user={user}/>
                                       )}
                                />
                                <Route history={history} path="/ex-stat/mapping/add/:account_id"
                                       render={(props) => (
                                           <ExStatMappingForm {...props} user={user}/>
                                       )}
                                />

                                {/* adminsIdList.includes(user.id)*/}
                                {
                                    user.role === 'admin' &&
                                    <Route history={history} path="/ex-stat/dashboard"
                                           render={(props) => (
                                               <ExStatDashboardList {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    user.role === 'admin' &&
                                    <Route history={history} path="/ex-stat/dashboard_tb"
                                           render={(props) => (
                                               <ExStatDashboard2List {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    user.role === 'admin' &&
                                    <Route history={history} path="/ex-stat/dashboard_clickadu"
                                           render={(props) => (
                                               <ExStatDashboard3List {...props} user={user}/>
                                           )}
                                    />
                                }

                                {
                                    user.role === 'admin' &&
                                    <Route history={history} path="/segment-rate/"
                                           render={(props) => (
                                               <SegmentRatesList {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    user.role === 'admin' &&
                                    <Route history={history} path="/segment-defaults/edit/:segment_id"
                                           render={(props) => (
                                               <SegmentDefaultsEditForm {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    user.role === 'admin' &&
                                    <Route history={history} path="/segment-defaults/"
                                           render={(props) => (
                                               <SegmentDefaultsList {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    nftAdminsIdList.includes(user.id) &&
                                    <Route history={history} path="/nft/collection/add/"
                                           render={(props) => (
                                               <NftCollectionAddForm {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    nftAdminsIdList.includes(user.id) &&
                                    <Route history={history} path="/nft/collections/"
                                           render={(props) => (
                                               <NftCollectionList {...props} user={user}/>
                                           )}
                                    />
                                }
                                {
                                    nftAdminsIdList.includes(user.id) &&
                                    <Route history={history} path="/nft/tokens/"
                                           render={(props) => (
                                               <NftTokenList {...props} user={user}/>
                                           )}
                                    />
                                }

                                <Route history={history} path='/dashboard' component={Dashboard}/>
                                <Route history={history} path='/offers' component={Offers}/>
                                <Route history={history} path='/offer/:offerId' component={Offer}/>
                                <Route history={history} path='/traffic-sources' component={TrafficSources}/>
                                <Route history={history} path='/payments' component={Payments}/>
                                <Route history={history} path='/stats' component={Stats}/>
                                <Route history={history} path='/settings' component={Settings}/>
                                <Route history={history} path='/terms' component={Terms}/>
                                <Route history={history} path='/privacy' component={Privacy}/>
                                <Route history={history} path='/help' component={Help}/>
                                <Route history={history} path='/support' component={Support}/>

                                <Route history={history} path='/stats' component={Lss}/>
                                <Route history={history} path='/rates' component={Rates}/>

                                <Route history={history} path='/empty' component={Empty}/>
                                <Route history={history} path='/dev' component={Develop}/>
                                <Route history={history} path='/logout' component={Logout}/>
                                <Redirect from='/' to='/empty'/>
                            </Switch>
                        }
                    </main>

                    <Footer/>
                </div>
            </div>
        );
    }
}

export default withRouter(App);
