import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./../../Settings/Tab";
import ButtonSubmit from "../../../components/ButtonSubmit";
import AlertSuccess from "../../../components/AlertSuccess";
import {useZoneRequest, useZoneUpdate} from "../../../../data/zone";
import {useInput} from "../../../../components/input-hook";
import SelectCountry2 from "../../../components/SelectCountry2";
import getZoneRevenueModelsList from "../../../../data/zone_revenue_models_list";
import SelectCountry3 from "../../../components/SelectCountry3";

export default function ExStatZoneEditFormTabCustomSettings({tabActiveID, zoneID}) {
    const listRevenueModels = getZoneRevenueModelsList();

    const [{data: profile, isLoading, error}] = useZoneRequest(zoneID);
    const [{
        data: updateProfile,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useZoneUpdate(zoneID);

    const {
        value: customRevenueModel,
        setValue: setCustomRevenueModel,
        error: errorCustomRevenueModel,
        setError: setErrorCustomRevenueModel,
        bind: bindCustomRevenueModel
    } = useInput('');
    const {
        value: customTitle,
        setValue: setCustomTitle,
        error: errorCustomTitle,
        setError: setErrorCustomTitle,
        bind: bindCustomTitle
    } = useInput('');

    const setFromResponse = (result) => {
        console.log('result', result)
        setCustomTitle(result.custom_title ? result.custom_title : '');
        setCustomRevenueModel(result.custom_revenue_model ? result.custom_revenue_model : '');
    }

    useEffect(() => {
        setFromResponse(profile);
    }, [profile]);

    useEffect(() => {
        if (updateProfile) {
            setFromResponse(updateProfile);
        }
    }, [updateProfile]);

    useEffect(() => {
        let errors = {
            custom_title: '',
            custom_revenue_model: '',
        };
        dataErrors.forEach(v => {
            if (v.field === 'custom_title') {
                errors.custom_title = v.message
            } else if (v.field === 'custom_revenue_model') {
                errors.custom_revenue_model = v.message
            }
        })

        setErrorCustomTitle(errors.custom_title);
        setErrorCustomRevenueModel(errors.custom_revenue_model);
    }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            custom_revenue_model: customRevenueModel,
            custom_title: customTitle,
        };
        setRequestData(data);
    }

    return (
        <Tab tabID="custom_settings" tabActiveID={tabActiveID} isLoading={isLoading} title="Custom Settings">
            <form onSubmit={handleSubmit}>
                {
                    updateProfile && <AlertSuccess message="Data updated successfully"/>
                }

                <div className="form-group">
                    {/*                    <label htmlFor="inputCustomTitle">Custom title</label>
                    <input type="text" id="inputCustomTitle"
                           placeholder="Custom title for zone"
                           className={"form-control " + (errorCustomTitle.length > 0 ? "is-invalid" : '')}
                           {...bindCustomTitle}/>
                    <div className="invalid-feedback">{errorCustomTitle}</div>*/}

                    <label htmlFor="inputCustomTitle">Revenue Model</label>

                    {customRevenueModel}

                    <SelectCountry3 value={customRevenueModel} updateValue={v => setCustomRevenueModel(v)}
                                    options={listRevenueModels}/>
                    {
                        errorCustomRevenueModel &&
                        <div className="invalid-feedback" style={{display: "block"}}>{errorCustomRevenueModel}</div>
                    }
                </div>

                <div className="form-group">
                    <label htmlFor="inputCustomTitle">Custom title</label>
                    <input type="text" id="inputCustomTitle"
                           placeholder="Custom title for zone"
                           className={"form-control " + (errorCustomTitle.length > 0 ? "is-invalid" : '')}
                           {...bindCustomTitle}/>
                    <div className="invalid-feedback">{errorCustomTitle}</div>
                </div>

                <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
            </form>
        </Tab>
    )
}

ExStatZoneEditFormTabCustomSettings.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
