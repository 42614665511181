import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from "react-router-dom";
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import Table from "../../../components/TablePaginated";
import DataModel from "./DataNftToken";
import TableParams from "./ListTableParams";
import {consoleLog} from "../../../../components/console";
import ajax from "../../../../data/ajax";
import SelectMulti from "../../../components/SelectMulti";
import Loading from "../../../components/Loading";
import DataStates from "../Collection/data_states";

const NftTokenList = ({user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const history = useHistory();

    const listState = DataStates.getDataStatesList();

    const [{
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [filterState, setFilterState] = useState([]);
    const [filterCollection, setFilterCollection] = useState([]);
    const [filterHasPriceBuyNow, setFilterHasPriceBuyNow] = useState([]);
    const [filterForBuy, setFilterForBuy] = useState([1]);
    const [filterButton, setFilterButton] = useState(0);

    const [listCollection, setListCollection] = useState([]);
    const [listListHasPriceBuyNow, setListHasPriceBuyNow] = useState([]);
    const [listForBuy, setListForBuy] = useState([]);

    const [params, setParams] = useState({});
    const [{data, isLoading}, setUrlParams] = DataModel();

    useEffect(() => {
        const fetchData = async () => {
            const promises = [
                axios.get(window.trackerBaseUrl + "nft/collection-list", {headers: ajax.getHeaders()}),
            ];
            Promise.all(promises).then(function (results) {
                // consoleLog(results)

                const accounts = results[0].data.map(i => {
                    return {
                        value: i.id,
                        label: i.id + ' / ' + i.name,
                    };
                });
                setListCollection(accounts);

                const listHasPriceBuyNow = [
                    {value: 1, label: 'Has price by now'},
                ];
                setListHasPriceBuyNow(listHasPriceBuyNow);

                const listForBuy = [
                    {value: 1, label: 'Recommended to buy'},
                ];
                setListForBuy(listForBuy);

                const searchParams = new URLSearchParams(location.search)
                const p = Object.fromEntries(searchParams);

                const statesValues = listState.map(e => e.value + '');
                let urlStates = ("state" in p) ? p.state.split(',') : filterState;
                urlStates = urlStates.filter(v => statesValues.indexOf(v) !== -1).map(v => parseInt(v, 10));
                setFilterState(urlStates);
                p.state = urlStates;

                const collectionValues = accounts.map(e => e.value);
                let urlCollections = ("collection" in p) ? p.collection.split(',') : filterCollection;
                urlCollections = urlCollections.filter(v => collectionValues.indexOf(v) !== -1);
                setFilterCollection(urlCollections);
                p.collection = urlCollections;

                const hpbnValues = listHasPriceBuyNow.map(e => e.value + '');
                let hpbnStates = ("has_price_buy_now" in p) ? p.has_price_buy_now.split(',') : filterHasPriceBuyNow;
                hpbnStates = hpbnStates.filter(v => hpbnValues.indexOf(v) !== -1).map(v => parseInt(v, 10));
                setFilterHasPriceBuyNow(hpbnStates);
                p.has_price_buy_now = hpbnStates;

                const fbValues = listForBuy.map(e => e.value + '');
                let fbStates = ("for_buy" in p) ? p.for_buy.split(',') : filterForBuy;
                fbStates = fbStates.filter(v => fbValues.indexOf(v) !== -1).map(v => parseInt(v, 10));
                setFilterForBuy(fbStates);
                p.for_buy = fbStates;

                setParams(p);

                setIsLoaded(true);
            });
        };

        setIsLoaded(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            let params = {
                state: filterState,
                collection: filterCollection,
                has_price_buy_now: filterHasPriceBuyNow,
                for_buy: filterForBuy,
            }

            setParams(params)
        }
    }, [filterState, filterCollection, filterHasPriceBuyNow, filterForBuy, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        history.push(location.pathname + '?' + (new URLSearchParams(params)).toString());
        setUrlParams(params);
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Tokens"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <SelectMulti value={filterState} updateValue={v => setFilterState(v)}
                                     options={listState} defaultLabel="Select state"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterCollection} updateValue={v => setFilterCollection(v)}
                                     options={listCollection} defaultLabel="Select collection"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterHasPriceBuyNow} updateValue={v => setFilterHasPriceBuyNow(v)}
                                     options={listListHasPriceBuyNow} defaultLabel="Select filter `now buy price`"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMulti value={filterForBuy} updateValue={v => setFilterForBuy(v)}
                                     options={listForBuy} defaultLabel="Select buy recommendation"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}/>
                }
            </Card>
        </Container>
    );
}

export default NftTokenList;
