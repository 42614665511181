import './scss/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import * as serviceWorker from './app/serviceWorker';

import {Router} from "react-router-dom"
import {createBrowserHistory} from 'history'

const history = createBrowserHistory()

window.trackerBaseUrl = process.env.REACT_APP_API_URL;

ReactDOM.render(
    // <React.StrictMode>
    <Router history={history}>
        <App/>
    </Router>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);


// console.log("ENV")
// console.log(process.env)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


if (process.env.REACT_APP_INTERCOM_ID) {
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
}
