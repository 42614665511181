import getZoneRevenueModelsList from "../data/zone_revenue_models_list";

export function getZoneRevenueModelName(val) {
	let title = "Unknown";
	const list = getZoneRevenueModelsList();
	list.forEach(i => {
		if (i.value == val) {
			title = i.label;
		}
	})

	return title;
}

